import './form.css'


const Form = () => {
  return (
		<>
			<form
				className='form'
				action='https://formspree.io/f/manwgnzr'
				method='POST'
			>
				<h3 className='textFormHeader'>
					Оставьте заявку, и мы сможем реализовать ваш проект, не дожидаясь
					открытия сайта
				</h3>
				<div className='formInputContainer'>
					<input
						className='myInput inputName'
						type='text'
						name='user'
						placeholder='Ваше имя'
					/>
					<input
						className='myInput inputTel'
						type='tel'
						name='phone'
						placeholder='Tелефон'
					/>
					<input
						className='myInput inputText'
						name='text'
						placeholder='Краткое описание задачи'
					></input>
				</div>

				<button className='submitBtn' type='submit'>
					Отправить
				</button>
			</form>

			<div className='containerBtnBottom'>
				<a href='tel:+79137035630' className='link linkTel'>
					+7 (913) 703 56 30
				</a>

				<a href='https://t.me/woojicore' className='link linkTelega'>
					Наш канал в телеграм
				</a>
			</div>
		</>
	)
}
 
export default Form;