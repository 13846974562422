import './app.css'
import Layout from './layout/layout'
import SorryPage from './page/page';



function App() {

	return (
		<div className='app'>
			<Layout>
				<SorryPage />
			</Layout>
		</div>
	)

}

export default App


