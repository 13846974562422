
import './layuot.css'

const Layout = ({children}) => {
  return (
		<div className='layoutContainer'>
			<div className='redRow leftRow'></div>
			<div className='redRow rightRow'></div>
			{children}
			
		</div>
	)
}
 
export default Layout;