import './page.css'
import woman from '../images/woman.png'
import Form from '../form/form'

const SorryPage = () => {
  return (
		<div className='sorryPage'>
			<header className='headerSorryPage'>
				<h1 className='titlePage'>
					wo<span className='redColor'>o</span>ji
				</h1>
			</header>

			<div className='startBlock'>
				<div className='nameBlock'>
					<h2 className='namePage leftName'>WEB</h2>
					<h2 className='namePage rightName'>BURO</h2>
				</div>

				<div className='textBlock'>
					<h2 className='textStartBlock'>
						Мы приводим всё в порядок, чтобы стать ещё лучше, но остаёмся на
						связи
					</h2>
					<div className='redCenter'></div>
					<img className='imgWoman' src={woman} alt='' />
				</div>
			</div>
      <Form />
		</div>
	)
}
 
export default SorryPage;